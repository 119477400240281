import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["carrier", "shippingMethod", "decorator"];

  connect() {
    console.log('CarrierShippingMethodController#connect');
    this.updateShippingMethods();
  }

  updateShippingMethods() {
    const carrierId = this.carrierTarget.value;
    const decoratorId = this.decoratorTarget.value;
    
    // Clear shipping methods when carrier id is not selected.
    if (!carrierId || !decoratorId) {
      this.clearShippingMethods();
      return;
    }
    
    const url = `/administration/selections/decorators/${decoratorId}/carriers/${carrierId}/shipping_methods`;
    
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => this.populateShippingMethods(data))
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
        // If the server responds with an error (ex. 404 for carrier not found),
        // clear the shipping methods.
        this.clearShippingMethods();
      });
  }

  populateShippingMethods(data) {
    console.log(data.results);
    const shippingMethodSelect = this.shippingMethodTarget;
    const selectedShippingMethodId = shippingMethodSelect.value;
    const options = data.results.map(({ id, text }) => {
      const option = document.createElement('option');
      option.value = id;
      option.textContent = text;
      return option;
    });

    // Clear the shipping method options.
    shippingMethodSelect.innerHTML = '';

    // Add the options to the select element.
    options.forEach(option => {
      shippingMethodSelect.appendChild(option);
    });

    // Select the previously selected shipping method, if available.
    if (selectedShippingMethodId) {
      shippingMethodSelect.value = selectedShippingMethodId;
    }
  }

  clearShippingMethods() {
    this.shippingMethodTarget.innerHTML = '';
  }
}
