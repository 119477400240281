import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    currentBalance: Number,
    memberName: String
  }

  connect() {
    console.log("GiftCardsConfirmationController connected to the DOM");
  }

  showModal(event) {
    event.preventDefault(); // Prevent the form from submitting
  
    const button = event.currentTarget;
    const form = button.closest("form");
    this.currentForm = form; // Temporarily store the form for later use
  
    // Adjust to capture the gift card ID from the form's data attribute
    const giftCardId = form.dataset.giftCardId;
    console.log("Gift card ID: ", giftCardId);
  
    console.log(form.dataset)
  
    // Elements
    const modal = document.getElementById("customModal");
    const confirmationMessage = document.getElementById("confirmationMessage");
    const confirmButton = document.getElementById("gift_card_modal_confirm_button");
  
    // Adjusted to access data from the parent container holding reward member attributes
    const memberCardsContainer = document.querySelector(".member-cards");

    const currentBalance = parseInt(memberCardsContainer.dataset.giftCardsConfirmationCurrentBalanceValue, 10);
    const memberName = memberCardsContainer.dataset.giftCardsConfirmationMemberNameValue;
    const email = memberCardsContainer.dataset.giftCardsConfirmationMemberEmailValue;
  
    // Existing logic to handle points addition and validation
    const pointsToAdd = form.querySelector('input[name="gift_card[new_points]"]').value;
    const pointsToAddInt = parseInt(pointsToAdd, 10);
  
    // Validate points to add
    if (isNaN(pointsToAddInt) || pointsToAddInt <= 0) {
      confirmationMessage.textContent = "Please enter a valid number of points to add.";
      confirmButton.style.display = "none";
    } else {
      const newBalance = currentBalance + pointsToAddInt;
      console.log(email)
      let notificationMessage = email ? "A safety point notification email will be sent." : "The reward member won't be notified: no email address.";
      let message = `This will update ${memberName}'s current point balance of ${currentBalance} by ${pointsToAdd}. The new point balance will be ${newBalance}. Are you sure?<br/><br/>${notificationMessage}`;
      confirmationMessage.innerHTML = message;
      confirmButton.style.display = "inline-block";
    }
  
    // Show modal
    modal.style.display = "block";
    modal.style.opacity = 1;
  }
  
  
  closeModal() {
    document.getElementById("customModal").style.display = "none";
  }
  
  confirmUpdate() {
    if (this.currentForm) {
      this.currentForm.submit();
    }
    this.closeModal();
  }
}
