// app/javascript/controllers/decorations_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["decoratorSelect", "printLocationSelect", "fulfillEngineId"]

  connect() {

    console.log("DecorationsController connect()");
    // If there's no selected decorator when the page loads, default to FulfillEngine.
    if (!this.decoratorSelectTarget.value) {
      this.decoratorSelectTarget.value = this.fulfillEngineIdTarget.value;
    }

    console.log("DecorationsController connect() decoratorSelectTarget.value = " + this.decoratorSelectTarget.value);
    // Load the appropriate print locations based on the selected decorator.
    this.loadPrintLocations();
  }

  // Call this method when the decorator select box value changes.
  onDecoratorChange() {
    this.loadPrintLocations();
  }

  loadPrintLocations() {
    const decoratorId = this.decoratorSelectTarget.value;
    const selectedPrintLocationId = this.printLocationSelectTarget.dataset.selectedPrintLocationId;
    fetch('/administration/selections/print_locations/' + decoratorId)
      .then(response => response.json())
      .then(data => {
        const printLocations = data.results;
        this.printLocationSelectTarget.innerHTML = "";
        printLocations.forEach((printLocation) => {
          const opt = document.createElement('option');
          opt.value = printLocation.id;
          opt.innerHTML = printLocation.text;
          if (printLocation.id.toString() === selectedPrintLocationId) {
            opt.selected = true;
          }
          this.printLocationSelectTarget.appendChild(opt);
        });
      });
  }
}
