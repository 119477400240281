// app/javascript/controllers/clickable_row_controller.js

import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
    this.element.style.cursor = "pointer";
  }

  goToUrl() {
    window.location.href = this.urlValue;
  }
}
