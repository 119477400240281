import { logger } from "@rails/actioncable"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "widthInches", 
    "heightInches", 
    "horizontalPlacement", 
    "verticalPlacement", 
    "colors"
  ]

  initialize() {
    this.toggleFields()
  }

  toggleFields() {
    const decorationMethod = this.decorationMethodTarget.value;
    const isEmbroidery = decorationMethod === "embroidery";
    console.log("isEmbroidery", isEmbroidery)
    this.widthInchesTargets.forEach(el => {
      el.classList.toggle("d-none", !isEmbroidery);
      el.disabled = !isEmbroidery;
    });
    this.heightInchesTargets.forEach(el => {
      el.classList.toggle("d-none", !isEmbroidery);
      el.disabled = !isEmbroidery;
    });
    this.horizontalPlacementTargets.forEach(el => {
      el.classList.toggle("d-none", !isEmbroidery);
      el.disabled = !isEmbroidery;
    });
    this.verticalPlacementTargets.forEach(el => {
      el.classList.toggle("d-none", !isEmbroidery);
      el.disabled = !isEmbroidery;
    });
    this.colorsTargets.forEach(el => {
      el.classList.toggle("d-none", isEmbroidery);
      el.disabled = isEmbroidery;
    });
  }

  get decorationMethodTarget() {
    return document.querySelector("#decoration_decoration_method")
  }
}
