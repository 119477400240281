import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["decoratorSelect", "showdownOptions", "blankField", "productId"];

  connect() {
    console.log("Connected!");

    this.productId = this.hasProductIdTarget ? this.productIdTarget.value : null;

    // Evaluate the showdown options on page load
    this.toggleShowdownOptions();

    if (this.hasBlankFieldTarget) {
      this.blankFieldTarget.addEventListener("change", this.updateShowdownOptions.bind(this));

      // Check if there is a value in the blankFieldTarget on load
      if (this.blankFieldTarget.value) {
        this.updateShowdownOptions(this.blankFieldTarget.value);
      }
    }

    // Listen for the autocomplete change event
    // This event is fired when the user selects a new value from the autocomplete field
    this.element.addEventListener("autocomplete.change", (event) => {
      const { value } = event.detail;
      this.updateShowdownOptions({ target: { value } });
    });
  }

  toggleShowdownOptions() {
    let showdownSelected = false;
    let blankDecoratorIncludesShowdown = false;

    if (this.hasDecoratorSelectTarget) {
      const selectedDecorators = Array.from(this.decoratorSelectTarget.selectedOptions).map(option => option.text);
      showdownSelected = selectedDecorators.includes("Showdown Displays");
    }

    if (this.hasBlankFieldTarget && this.blankFieldTarget.value) {
      const blankId = this.blankFieldTarget.value;
      this.checkBlankForShowdown(blankId).then(blankIncludesShowdown => {
        blankDecoratorIncludesShowdown = blankIncludesShowdown;
        this.updateShowdownDisplay(showdownSelected || blankDecoratorIncludesShowdown);
      }).catch(error => {
        console.error('Error checking blank for Showdown:', error);
        this.updateShowdownDisplay(showdownSelected);
      });
    } else {
      this.updateShowdownDisplay(showdownSelected);
    }
  }

  updateShowdownOptions(event) {
    if (!event || !event.target || event.target.value === undefined) {
      // Handle the case where event or event.target is undefined or event.target.value is undefined
      return;
    }

    let blankId = event.target.value;

    if (blankId === null && this.hasBlankFieldTarget) {
      blankId = this.blankFieldTarget.value;
    }

    if (blankId) {
      console.log('Blank ID:', blankId);

      this.fetchShowdownOptions(blankId);

      this.checkBlankForShowdown(blankId).then(blankIncludesShowdown => {
        let showdownSelected = false;
        if (this.hasDecoratorSelectTarget) {
          const selectedDecorators = Array.from(this.decoratorSelectTarget.selectedOptions).map(option => option.text);
          showdownSelected = selectedDecorators.includes("Showdown Displays");
        }

        
        this.updateShowdownDisplay(showdownSelected || blankIncludesShowdown);
      }).catch(error => {
        console.error('Error checking blank for Showdown:', error);
        this.toggleShowdownOptions();
      });
    }
  }

  async fetchShowdownOptions(blankId) {
    const url = `/administration/blank_attributes/${blankId}?product_id=${this.productId}`;
    const response = await fetch(url, {
      headers: { Accept: "text/vnd.turbo-stream.html" }
    });
  
    if (response.ok) {
      const turboStream = await response.text();
      
      if (this.attributeValuesExist(turboStream)) {
        document.body.insertAdjacentHTML("beforeend", turboStream);
        this.updateShowdownDisplay(true); // Show the panel if attribute values exist
      } else {
        this.clearAttributeValuesFrame();
        this.updateShowdownDisplay(false); // Hide the panel if no attribute values
      }
    } else {
      console.error(`Error fetching showdown options: ${response.statusText}`);
      this.updateShowdownDisplay(false); // Hide the panel on fetch error
    }
  }

  async checkBlankForShowdown(blankId) {
    const url = `/administration/selections/blanks/${blankId}`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data.results.includes_showdown;
  }

  updateShowdownDisplay(show) {
    if (show) {
      this.showdownOptionsTarget.style.display = "block";
    } else {
      this.showdownOptionsTarget.style.display = "none";
    }
  }

  attributeValuesExist(turboStream) {
    // Create a temporary div to parse the response and check for attribute values
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = turboStream;
  
    const templateElement = tempDiv.querySelector("turbo-stream template");
  
    if (templateElement) {
      const templateContent = templateElement.content;
      return templateContent && templateContent.children.length > 0;
    }
  
    return false;
  }

  clearAttributeValuesFrame() {
    const attributeValuesFrame = document.querySelector("#attribute-values-frame");
    if (attributeValuesFrame) {
      attributeValuesFrame.innerHTML = "";
    }
  }
  
}
