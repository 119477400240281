// app/javascript/controllers/toggle_note_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "checkbox", 
    "productionErrorFields", 
    "faultRadios", 
    "reason",
    "images", 
    "hiddenDestroyField"
  ];

  connect() {
    this.toggleNote();
  }

  toggleNote(event) {
    const isChecked = this.checkboxTarget.checked;
    this.updateDestroyField(isChecked);

    [this.productionErrorFieldsTarget, this.faultRadiosTarget, this.imagesTarget]
      .forEach((element) => {
        element.style.display = isChecked ? "block" : "none";
      });
  }

  updateDestroyField(isChecked) {
    this.hiddenDestroyFieldTarget.value = isChecked ? "0" : "1";
  }
}
