// app/javascript/controllers/decorator_auto_fulfillment_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  toggle(event) {
    event.preventDefault()
    const checked = this.checkboxTarget.checked
    const url = this.checkboxTarget.dataset.url

    fetch(url, {
      method: "PATCH",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
      },
      credentials: "same-origin",
      body: JSON.stringify({ auto_fulfillment: checked })
    }).then(response => {
      if (!response.ok) {
        console.error("Failed to toggle auto fulfillment", response)
        this.checkboxTarget.checked = !checked
      }
    })
  }
}
