import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "input", "output" ];
  static values = { showIf: String }

  connect() {
    this.toggle()
  }

  toggle() {
    let inputValue = this.inputTarget.value
    let showIfValue = this.showIfValue

    this.outputTarget.hidden = inputValue != showIfValue

    if (inputValue === showIfValue) {
      this.enableOutputFields()
    } else {
      this.disableOutputFields()
    }
  }

  enableOutputFields() {
    let fields = this.outputTarget.querySelectorAll("input, select")

    for (let field of fields) {
      field.disabled = false
    }
  }

  disableOutputFields() {
    let fields = this.outputTarget.querySelectorAll("input, select")

    for (let field of fields) {
      field.disabled = true
    }
  }
}
