import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "row", "tag" ]

  connect() {
    console.log("Highlight controller connected")
    this.rowTargets.forEach((row) => {
      if (row.dataset.international === "true") {
        row.classList.add("highlight")
      }
    })
  }
}
