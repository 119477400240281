// app/javascript/controllers/enable_turbo_controller.js
import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  connect() {
    Turbo.session.drive = true;
  }

  disconnect() {
    Turbo.session.drive = false;
  }
}
