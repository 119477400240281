import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"];

  submitForm(event) {
    event.preventDefault();
    this.selectTarget.form.submit();
  }
}
