import { Controller } from "stimulus"
import 'select2';

export default class extends Controller {
  static targets = ["shop", "product", "variant", "bundleVariants", "productVariantIds", "removeButton"]

  connect() {
    console.log("BundleFormController connected")
    this.setupShopSelection();
    this.setupProductSelection();
  }

  setupShopSelection() {
    console.log("setupShopSelection")
    let self = this;

    $(this.shopTarget).select2({
      placeholder: "Select a Shop",
      width: '100%'
    });

    $(this.shopTarget).on('change', function(e) {
      self.setupProductSelection();
    });
  }

  setupProductSelection() {
    console.log("setupProductSelection")
    let self = this;

    // clear product select
    $(this.productTarget).val(null).trigger('change');
    $(this.productTarget).empty();

    $(this.productTarget).select2({
      placeholder: "Select a Product",
      ajax: {
        url: '/administration/selections/products/' + $(self.shopTarget).val(),
        dataType: 'json',
        processResults: function (data) {
          return {
            results: data.results
          };
        },
        error: function (jqXHR, exception) {
          console.error(jqXHR, exception);
        }
      },
      width: '100%'
    });

    $(this.productTarget).on('select2:select', function(e) {
      $(self.variantTarget).show();

      $.ajax({
        url: '/administration/selections/products/' + e.params.data.id + '/product_variants',
        success: function(data) {
          var select = $(self.variantTarget);
          select.empty();
          // Adding a blank option as a placeholder
          select.append('<option></option>');
          $.each(data.results, function(index, value) {
            select.append('<option value="' + value.id + '">' + value.text + '</option>');
          });
          $(self.variantTarget).select2({
            placeholder: "Select a Variant",
            width: '100%'
          });
        },
        error: function (jqXHR, exception) {
          console.error(jqXHR, exception);
        }
      });
    });

    $(this.productTarget).on('select2:unselect', function(e) {
      $(self.variantTarget).hide();
    });

    $(this.variantTarget).on('select2:select', function(e) {
      console.log(e.params.data.element.dataset.product_name);
      self.addVariantToBundle(e.params.data);
    });
  }

  addVariantToBundle(variant) {
    // Format product variant display text
    const variantText = variant.product_name + " / " + variant.text;
  
    // Add unique id for each variant
    const variantId = `variant-${variant.id}`;
  
    // Create new row element
    let row = $('<tr></tr>').attr('id', variantId);
  
    // Add variant name cell
    row.append('<td>' + variantText + '</td>');
  
    // Add quantity cell with input field
    row.append('<td><input type="number" id="bundle_bundle_items_attributes_' + variant.id + '_quantity" name="bundle[bundle_items_attributes][' + variant.id + '][quantity]" min="1" value="1" required></td>');
  
    // Add remove button cell
    row.append('<td><button data-action="click->bundle-form#removeVariant" class="remove-field" data-variant-id="' + variant.id + '">X</button></td>');
  
    // Add row to the table
    $(this.bundleVariantsTarget).append(row);
  
    // Add variant to the hidden field
    $('<input>').attr({
      type: 'hidden',
      id: 'bundle_bundle_items_attributes_' + variant.id + '_product_variant_id',
      name: 'bundle[bundle_items_attributes][' + variant.id + '][product_variant_id]',
      value: variant.id,
      data: { "bundle-form-target": "variantIds" }
    }).appendTo('form');
  
    // Add _destroy hidden field
    $('<input>').attr({
      type: 'hidden',
      id: 'bundle_bundle_items_attributes_' + variant.id + '__destroy',
      name: 'bundle[bundle_items_attributes][' + variant.id + '][_destroy]',
      value: 'false',
    }).appendTo('form');
  }
  
  removeVariant(e) {
    let self = this;
    // Get variant id from button data attribute
    let variantId = $(e.target).data('variant-id');

    // Remove list item from display list
    $('#variant-' + variantId).remove();

    // Remove variant from hidden field
    $('#bundle_product_variant_ids_' + variantId).remove();
  }
}
