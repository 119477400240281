import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["message"]

  connect() {
    console.log("CSV Upload Stimulus controller connected")
    this.boundHandleMessage = this.handleMessage.bind(this)
    window.addEventListener('csv-upload-message', this.boundHandleMessage)
  }

  disconnect() {
    window.removeEventListener('csv-upload-message', this.boundHandleMessage)
  }

  handleMessage(event) {
    console.log("Received data in CSV Upload Stimulus controller:", event.detail)
    if (event.detail.message && this.hasMessageTarget) {
      this.messageTarget.textContent = event.detail.message
      console.log("Updated message target with:", event.detail.message)
    } else {
      console.warn("Received data without a message key or no message target:", event.detail)
    }
  }
}