import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["threadColorsSelect"];

  connect() {
    console.log("Loaded shop-thread-colors controller...");
    const shopSelectElement = this.element.querySelector("select[name='decoration[shop_id]']");
    if (shopSelectElement) {
      this.selectShop({ target: shopSelectElement });
    }
  }

  threadColorsSelectTargetConnected(element) {
    console.log("Connected target!");
    console.log(element);
  }

  threadColorsSelectTargetDisconnected(element) {
    console.log("Disconnected target!");
  }

  selectShop(event) {
    const shopId = event.target.value;

    fetch(`/administration/shops/${shopId}/thread_colors`, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        let options = [`<option value="" disabled selected>Select a Thread Color Code</option>`];
        options = options.concat(data.thread_colors.map(
          (color) => `<option value="${color.code}">${color.code} | ${color.name}</option>`,
        ));

        if (this.threadColorsSelectTarget) {
          this.threadColorsSelectTarget.innerHTML = options.join("");
          this.threadColorsSelectTarget.disabled = false;
        } else {
          console.warn("Thread colors select target not found.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
