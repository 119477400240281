import { Controller } from "stimulus"

const TOPSHELF = 15;
const SHIPPING_POLICIES = {
  ALL_DELIVERY_OPTIONS: 'all_delivery_options',
  THIRD_PARTY_SHIPPING: 'third_party_shipping',
  DECORATOR_SHIPPING_TIERS: 'decorator_shipping_tiers'
};

export default class extends Controller {
  static targets = [
    "decorator",
    "shippingTierGroup",
    "shippingTier",
    "carrier",
    "service",
    "carrierLabel",
    "shippingMethodLabel",
    "shippingType",
    "decoratorShippingTier",
    "thirdPartyShipping",
    "supplierTrackingOverride",
    "shippingAccount",
    "shippingAccountLabel",
  ];

  decoratorName = null;
  shippingPolicy = null;
  acceptsThirdPartyShippingAccount = null;

  connect() {
    this.decoratorChanged();
  }

  async decoratorChanged() {
    await this.fetchAndProcessData();
    console.log("decorator name: "+ this.decoratorName);
    console.log("shipping policy: "+ this.shippingPolicy);
    console.log("accepts third party shipping account: "+ this.acceptsThirdPartyShippingAccount);
    this.updateSupplierTrackingOverride();
    const shippingPolicy = this.getDecoratorShippingPolicy();
    this.resetFields();
    this.handleShippingPolicy(shippingPolicy);
    this.checkAndToggleShippingOptions();
  }

  async shippingAccountChanged() {
    const shippingAccountId = this.shippingAccountTarget.value;

    if (shippingAccountId === "") { // Assuming that an empty string represents "None"
      this.resetCarrierAndShippingMethodSelects();
      return;
    }

    const carrierId = await this.fetchCarrierForShippingAccount(shippingAccountId);
    this.updateCarrierSelect(carrierId);
  }

  async fetchCarrierForShippingAccount(shippingAccountId) {
    const decoratorId = this.decoratorTarget.value;

    const url = `/administration/selections/decorators/${decoratorId}/shipping_accounts/${shippingAccountId}`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();

    return data.results.id;
  }

  updateCarrierSelect(carrierId) {
    const selectedOption = Array.from(this.carrierTarget.options).find(
      option => option.value == carrierId
    );

    if (selectedOption) {
      selectedOption.selected = true;
      this.carrierTarget.dispatchEvent(new Event('change', { 'bubbles': true }));
    }
  }

  async fetchAndProcessData() {
    try {
      const data = await this.fetchDecoratorData();
      this.processDecoratorData(data);
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
    this.updateSupplierTrackingOverride();
  }

  async fetchDecoratorData() {
    const decoratorId = this.decoratorTarget.value;
    const url = `/administration/selections/decorators/${decoratorId}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  }

  processDecoratorData(data) {
    this.decoratorName = data.name;
    this.shippingPolicy = data.shipping_policy;
    this.acceptsThirdPartyShippingAccount = data.accepts_third_party_shipping_account;
    this.thirdPartyShippingAccountNumberRequired = data.third_party_shipping_account_number_required;
    this.decoratorCarrierOptions = data.carrier_options;

    if (this.hasShippingTierTarget) {
      this.updateShippingTierOptions(data.shipping_tiers);
    }

    if (this.hasCarrierTarget) {
      this.updateCarrierOptions(data.carrier_options);
    }
    this.updateCarrierAndServiceVisibility(data.shipping_tiers);
  }

  updateShippingTierOptions(data) {
    this.shippingTierTarget.innerHTML = '';
    const display = data.length > 0 ? 'block' : 'none';
    this.shippingTierGroupTarget.style.display = display;
    this.createShippingTierOptions(data);
  }

  updateCarrierOptions(data) {
    this.carrierTarget.innerHTML = '';  // Clear existing options
    const display = data.length > 0 ? 'block' : 'none';
    this.carrierTarget.style.display = display;
    this.createCarrierOptions(data);
  }

  createCarrierOptions(data) {
    data.forEach(([text, id]) => this.createCarrierOption(text, id));
  }
  
  createCarrierOption(text, id) {
    const option = document.createElement('option');
    option.value = id || '';  // Set value to empty string for '(None)' option
    option.textContent = text;
  
    // Access the data attribute and compare
    const currentCarrierId = this.carrierTarget.dataset.currentCarrierId;
  
    option.selected = (id && id.toString() === currentCarrierId);
    this.carrierTarget.appendChild(option);
  }
  
  
  createShippingTierOption(id, text) {
    const selectedValue = this.shippingTierTarget.dataset.selectedShippingTier;
    const option = document.createElement('option');
    option.value = id || ''; // Set value to empty string for 'None' option
    option.textContent = text;
    option.selected = id == selectedValue;
    this.shippingTierTarget.appendChild(option);
  }

  createShippingTierOptions(data) {
    this.createShippingTierOption(null, '(None)');
    data.forEach(({ id, text }) => this.createShippingTierOption(id, text));
  }

  updateCarrierAndServiceVisibility(data) {
    const isVisible = data.length > 0;
    this.toggleDisplay([this.carrierTarget, this.serviceTarget, this.carrierLabelTarget, this.shippingMethodLabelTarget], isVisible);
  }

  updateSupplierTrackingOverride() {
    const display = parseInt(this.decoratorTarget.value) === TOPSHELF ? 'block' : 'none';
    this.supplierTrackingOverrideTarget.style.display = display;
  }

  resetCarrierAndShippingMethodSelects() {
    this.resetSelect(this.carrierTarget);
    this.resetSelect(this.serviceTarget); 
  }
  
  resetSelect(selectElement) {
    const noneOption = Array.from(selectElement.options).find(
      option => option.textContent === "(None)"
    ) || selectElement.options[0];
  
    if (noneOption && !noneOption.selected) {
      noneOption.selected = true;
  
      // Manually trigger the change event only if a change was made
      selectElement.dispatchEvent(new Event('change', { 'bubbles': true }));
    }
  }

  getDecoratorShippingPolicy() {
    return this.decoratorTarget.options[this.decoratorTarget.selectedIndex].dataset.shipping_policy;
  }

  toggleDisplay(elements, isVisible) {
    const display = isVisible ? '' : 'none';
    elements.forEach(element => element.style.display = display);
  }

  resetFields() {
    this.toggleDisplay([
      this.carrierTarget,
      this.serviceTarget,
      this.shippingMethodLabelTarget,
      this.shippingAccountTarget,
      this.shippingAccountLabelTarget,
      this.shippingTierTarget
    ], false);
  }

  resetFieldValues(fields) {
    fields.forEach(field => {
      field.value = "";
    });
  }

  shippingTierChanged() {
    if (this.shippingTierTarget.value) {
      this.resetCarrierAndShippingMethodSelects();
    }
  }

  carrierChanged() {
    if (this.carrierTarget.value) {
      this.resetShippingTierSelect();
    }
  }

  resetCarrierAndShippingMethodSelects() {
    if (this.shippingAccountTarget.value !== "None") {
      this.resetSelect(this.shippingAccountTarget);
    }

    if (this.carrierTarget.value !== "None") {
      this.resetSelect(this.carrierTarget);
      this.resetSelect(this.serviceTarget);
    }
  }

  resetShippingTierSelect() {
    if (this.shippingTierTarget.value !== "None") {
      this.resetSelect(this.shippingTierTarget);
    }
  }

  checkAndToggleShippingOptions() {
    if (this.requiresThirdPartyShippingAccount() && this.shippingAccountTarget.options.length <= 1) {
      this.toggleDisplay([
        this.carrierTarget,
        this.serviceTarget,
        this.shippingMethodLabelTarget,
        this.shippingAccountTarget,
        this.carrierLabelTarget,
        this.shippingAccountLabelTarget,
      ], false);
    }
  }
  
  requiresThirdPartyShippingAccount() {
    return this.thirdPartyShippingAccountNumberRequired;
  }
  
  handleShippingPolicy(shippingPolicy) {
    switch(shippingPolicy) {
      case SHIPPING_POLICIES.ALL_DELIVERY_OPTIONS:
        this.toggleDisplay([
          this.carrierTarget,
          this.serviceTarget,
          this.shippingMethodLabelTarget,
          this.shippingTierTarget,
          this.shippingTierGroupTarget
        ], true);
        if(this.acceptsThirdPartyShippingAccount) {  
          this.toggleDisplay([this.shippingAccountLabelTarget], true);
          this.toggleDisplay([this.shippingAccountTarget], true);
        }
        break;
      case SHIPPING_POLICIES.THIRD_PARTY_SHIPPING:
        this.toggleDisplay([
          this.carrierTarget,
          this.carrierLabelTarget,
          this.serviceTarget,
          this.shippingMethodLabelTarget,
          this.shippingAccountTarget,
          this.shippingAccountLabelTarget
        ], true);
        if(this.acceptsThirdPartyShippingAccount) {  
          this.toggleDisplay([this.shippingAccountLabelTarget], true);
          this.toggleDisplay([this.shippingAccountTarget], true);
        }
        break;
      case SHIPPING_POLICIES.DECORATOR_SHIPPING_TIERS:
        this.toggleDisplay([
          this.shippingTierTarget,
          this.shippingTierGroupTarget
        ], true);
        // Hide carrier and service for this policy
        this.toggleDisplay([
          this.carrierTarget,
          this.serviceTarget,
          this.carrierLabelTarget,
          this.shippingMethodLabelTarget
        ], false);
        this.resetFieldValues([this.carrierTarget, this.serviceTarget]);
        break;
      default:
        console.error("Invalid shipping policy: " + shippingPolicy);
        break;
    }
  }
}
